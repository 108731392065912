@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
	box-sizing:border-box;
}

body {
	font-family:"Montserrat", sans-serif;
	color:#222222;
	font-size:14px;
	line-height:1.8;
}

:root {
	--header-height:80px;
	--left-side-width:280px;
	--right-side-width:50px;
	--blocks-gap:35px;
	--border-shadow:0px 0px 3px rgba(100, 100, 100, 0.2);
}

html,
body {
	width:100%;
	height:100%;
}

a {
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.dashboard {
	width:100%;
	height:100%;
}

.dashboard__header {
	position:fixed;
	width:100%;
	top:0;
	left:0;
	z-index:1;
	height:var(--header-height);
	box-shadow:var(--border-shadow);
	padding:0 15px;
	
	display:flex;
	align-items:center;
	justify-content:space-between;
	flex-wrap:wrap;
	gap:30px;
	
	background-color:#ffffff;
}

.dashboard__header img {
	max-height:40px;
}

.dashboard__left {
	position:fixed;
	width:var(--left-side-width);
	height:100%;
	top:0;
	left:0;
	padding:0 0 0 0;
	
	background-color:#ffffff;
	box-shadow:var(--border-shadow);
}

.dashboard__right {
	position:fixed;
	width:var(--right-side-width);
	height:100%;
	top:0;
	right:0;
	padding:0 0 0 0;
	
	background-color:#ffffff;
	box-shadow:var(--border-shadow);
}

.dashboard__right-btn {
	width:100%;
	height:50px;
	cursor:pointer;
	padding-top:100%;
	border-bottom:1px solid #eeeeee;
	background-repeat:no-repeat;
	background-position:50% 50%;
	background-size:50% auto;
	
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.dashboard__right-btn:hover {
	background-color:#f6f6f6;
}

.dashboard__right-btn-user {
	background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23999999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-user'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' /%3E%3Cpath d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' /%3E%3C/svg%3E");
}

.dashboard__right-btn-logout {
	background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23999999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-logout'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2' /%3E%3Cpath d='M9 12h12l-3 -3' /%3E%3Cpath d='M18 15l3 -3' /%3E%3C/svg%3E");
}


.dashboard__content {
	/*
	padding:var(--blocks-gap);
	margin:var(--header-height) var(--right-side-width) 0 var(--left-side-width);
	*/
	
	/*padding:var(--blocks-gap);*/
	padding:15px calc(var(--right-side-width) + var(--blocks-gap)) var(--blocks-gap) calc(var(--left-side-width) + var(--blocks-gap));
	
	min-height:100%;
	background-color:#f9f9f9;
	display:flex;
	flex-direction:column;
}

.dashboard__page {
	flex:1;
	display:flex;
	flex-direction:column;
}

.page__breadcrumbs ul {
	list-style:none;
	margin:0;
	padding:0;
	display:flex;
	flex-wrap:wrap;
}

.page__breadcrumbs li {
	position:relative;
	padding:0 20px 0 0;
}

.page__breadcrumbs li:last-child {
	padding:0;
}

.page__breadcrumbs li:after {
	content:'-';
	position:absolute;
	top:0px;
	right:8px;
}

.page__breadcrumbs li:last-child:after {
	display:none;
}

.page__breadcrumbs li a {
	color:#000000;
	font-weight:400;
}

.page__head {
	display:flex;
	flex-direction:column;
	flex-wrap:wrap;
	gap:0px;
	margin:0 0 20px 0;
}

.page__head h2 {
	order:-1;
}

.page__block {
	background-color:#ffffff;
	border-radius:5px;
	box-shadow:0px 0px 10px rgba(100, 100, 100, 0.1);
	padding:30px 25px;
	flex:1;
}

.left__menu {
	/*margin:30px 0 0 0;*/
}

.left__menu-section-title {
	padding:10px 15px;
	display:flex;
	align-items:center;
	gap:15px;
	font-weight:500;
	font-size:12px;
	text-decoration:none !important;
	color:#222222;
	
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.left__menu-section + .left__menu-section {
	border-top:1px solid #dddddd;
}

.left__menu-section-title:hover {
	background-color:#f6f6f6;
	color:#222222;
}

.left__menu-section-title svg {
	width:20px;
}

.left__logo-link {
	display:flex;
	align-items:center;
	width:100%;
	padding:5px 15px;
	text-decoration:none !important;
	font-size:40px;
}

.left__logo-link svg {
	margin:0 8px 0 0;
}

.left__logo-link span {
	color:#000000;
}

.page__title {
	font-weight:600;
}

.toaster__close-btn {
	top:8px;
	left:unset;
	right:-5px;
	border:0;
}


@media(max-width: 575px) {
	
	.dashboard__left {
		position:relative;
		width:100%;
		height:auto;
	}
	
	.dashboard__right {
		position:relative;
		height:auto;
		width:100%;
		display:flex;
		justify-content:space-between;
		align-items:center;
		flex-wrap:wrap;
		gap:30px;
	}
	
	.dashboard__right-btn {
		width:50px;
		height:50px;
		padding-top:0;
	}
	
	.dashboard__content {
		padding:0 10px 30px;
		height:auto;
		min-height:unset;
	}
	
	.site__item {
		max-width: 100%;
		width: 100%;
	}
}
