@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
	box-sizing:border-box;
}

body {
	font-family:'Montserrat', sans-serif;
	color:#222222;
	font-size:16px;
}

html,
body {
	display:flex;
	width:100%;
	height:100%;
}

#root {
	display:flex;
	width:100%;
	height:100%;
}

img {
	max-width:100%;
}

.App {
	display:flex;
	width:100%;
	height:100%;
}

.loader {
    border:4px solid #f3f3f3;
    border-top:4px solid #ff7a00;
    border-radius:50%;
    width:50px;
    height:50px;
    animation:spin 1s linear infinite;
	margin:auto;
}

@keyframes spin {
    0% { transform:rotate(0deg); }
    100% { transform:rotate(360deg); }
}

.container {
	width:100%;
	padding:0 15px;
	margin:0 auto;
}

a {
	/*color:#c93939;*/
	
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.btn {
	display:inline-block;
	border:1px solid #cccccc;
	border-radius:5px;
	text-decoration:none !important;
	cursor:pointer;
	padding:10px 30px;
	font-size:14px;
	font-weight:600;
	background-color:#eeeeee;
	outline:none !important;
	box-shadow:none !important;
	
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.btn:hover {
	background-color:#dddddd;
}

.btn.is__loading {
	opacity:0.5;
}


.btn__red {
	display:inline-block;
	border:1px solid #c93939;
	border-radius:5px;
	text-decoration:none !important;
	cursor:pointer;
	padding:10px 30px;
	font-size:14px;
	font-weight:600;
	background-color:#c93939;
	color:#ffffff;
	
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.btn__red:hover {
	background-color:#a72020;
	color:#ffffff;
}

.btn__blue {
	display:inline-block;
	border:1px solid #203a71;
	border-radius:5px;
	text-decoration:none !important;
	cursor:pointer;
	padding:10px 30px;
	font-size:14px;
	font-weight:600;
	background-color:#203a71;
	color:#ffffff;
	
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.btn__blue:hover {
	background-color:#0d265b;
	color:#ffffff;
}

.auth__page {
	width:100%;
	display:flex;
	padding:15px;
	overflow:auto;
	background-color:#f6f6f6;
}

.auth__form {
	box-shadow:0px 0px 10px rgba(100, 100, 100, 0.2);
	margin:auto;
	padding:30px 30px;
	border-radius:10px;
	max-width:400px;
	width:100%;
	background-color:#ffffff;
}

.auth__form-logo {
	margin:0 0 15px 0;
}

.form__title {
	font-size:27px;
	margin:0 0 25px 0;
	font-weight:700;
}

.form__item {
	
}

.form__item + .form__item {
	margin:15px 0 0 0;
}

.form__item-label {
	margin:0 0 7px 0;
	line-height:1;
	font-size:14px;
	font-weight:500;
	display:flex;
	justify-content:space-between;
	align-items:center;
	gap:10px;
}

.form__item-label-error {
	color:#d10000;
	font-size:12px;
}

.form__item-input {
	width:100%;
	outline:none;
	border:1px solid #dddddd;
	padding:10px 15px;
	border-radius:3px;
	font-size:14px;
	font-weight:500;
}

.form__item-input-error {
	border:1px solid #d10000;
	background-color:#fff3f2;
}

.form__item-btns {
	display:flex;
	justify-content:space-between;
	align-items:center;
	gap:10px;
	margin:30px 0 0 0 !important;
}

.auth__form-auth .form__item-btns {
	display:block;
}

.auth__form-auth .form__item-btns .btn {
	width:100%;
}

.form__error {
	margin:15px 0 0 0;
	border:1px solid #d10000;
	background-color:#fff3f2;
	padding:15px 20px;
	border-radius:3px;
	color:#d10000;
	font-size:14px;
	font-weight:500;
}

.form__error ul {
	list-style:none;
	margin:0;
	padding:0;
}

.form__error li {
	position:relative;
}

.form__error li + li {
	margin:8px 0 0 0;
}

.form__error li:before {
	content:'- ';
}

.form__error li:first-child:last-child:before {
	content:'';
}

.form__item-links {
	display:flex;
	justify-content:space-between;
	align-items:center;
	gap:10px;
	border-top:1px solid #eeeeee;
	margin:25px 0 0 0 !important;
	padding:15px 0 0 0;
	font-size:14px;
	font-weight:500;
}

.form__item-links a {
	font-size:13px;
	color:#203a71;
	font-weight:500;
	text-decoration:none;
	border-bottom:1px solid currentColor;
	line-height:1.1;
}

.form__item-links a:hover {
	color:#a72020;
}

.form__item-link a {
	font-size:13px;
	color:#203a71;
	font-weight:500;
	text-decoration:none;
	border-bottom:1px solid currentColor;
	line-height:1.1;
}

.form__item-link a:hover {
	color:#a72020;
}

.form__item-policy {
	font-size:13px;
	margin:30px 0 0 0 !important;
	font-weight:500;
}

.auth__form-confirm {
	text-align:center;
}

.auth__form-confirm-btns {
	text-align:center;
	margin:25px 0 0 0;
}

.Navigation {
	display:flex;
	gap:20px;
}

.page {
	width:100%;
	display:flex;
	flex-direction:column;
}

.header {
	
}

.header__content {
	border-bottom:1px solid #f3f3f3;
	padding:10px 0;
	display:flex;
	justify-content:space-between;
	align-items:center;
	gap:20px;
}

.header__logo-link {
	font-size:30px;
	font-weight:800;
	color:#222222;
}

.header__logo-link:hover {
	color:#222222;
	text-decoration:none;
}

.header__logo-link img {
	/*max-width:80px;*/
}

.header__user {
	display:flex;
	justify-content:space-between;
	align-items:center;
	gap:10px;
}

.header__user-avatar {
	flex:none;
	width:30px;
	height:30px;
	border-radius:50%;
	background-color:#eeeeee;
	line-height:30px;
	text-align:center;
	font-weight:600;
	font-size:16px;
	order:1;
}

.header__user-name {
	font-size:16px;
	font-weight:600;
}

.footer {
	margin:auto 0 0 0;
	background-color:#f3f3f3;
}

.footer__content {
	display:flex;
	align-items:center;
	gap:30px;
	padding:20px 0;
}

.header__user-logout {
	cursor:pointer;
	margin:0 0 0 30px;
}

.header__user-logout svg {
	fill:#cccccc;
}

.sites__header {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	gap:20px;
	margin:15px 0 0 0;
}

.site-list {
	display:flex;
	flex-wrap:wrap;
	gap:20px;
	margin:20px 0;
}

.site__item {
	border:1px solid #cccccc;
	border-radius:10px;
	padding:15px 20px;
	max-width:calc((100% / 2) - 10px);
	width:100%;
	flex:none;
}

.site__item.is__skeleton {
	border:1px solid #f5f5f5;
}

.site__item-name {
	font-size:18px;
	font-weight:700;
	line-height:1;
	display:flex;
	justify-content:space-between;
	align-items:center;
	gap:10px;
}

.site__item-name svg {
	fill:#008100;
	width:18px;
}

.site__item-name svg:last-child {
	margin:0 0 0 auto;
	fill:#222222;
}

.site__item-name a {
	margin:0 0 0 auto;
}

.site__item-dates {
	margin:20px 0 0 0;
	display:flex;
	align-items:center;
	gap:15px;
}

.site__item-domain {
	display:flex;
	align-items:center;
	line-height:1;
	color:#008100;
	border-radius:5px;
	background-color:#ffffff;
	font-weight:700;
	/*flex:none;
	width:calc(50% - 10px);*/
}

.site__item-domain svg {
	fill:#008100;
	width:16px;
}

.site__item-domain.disabled svg {
	fill:#999999;
}

.site__item-domain-date {
	font-size:12px;
	color:#999999;
	font-weight:400;
	margin:0 0 0 5px;
	/*display:none;*/
}

.site__item-ssl {
	display:flex;
	align-items:center;
	line-height:1;
	color:#008100;
	border-radius:5px;
	background-color:#ffffff;
	font-weight:700;
	flex:none;
	width:calc(50% - 10px);
}

.site__item-ssl svg {
	fill:#008100;
	width:16px;
}

.site__item-ssl.disabled svg {
	fill:#999999;
}

.site__item-ssl-date {
	font-size:12px;
	color:#999999;
	font-weight:400;
	margin:0 0 0 5px;
	/*display:none;*/
}

.site__item.disabled .site__item-name {
	color:#999999;
}

.site__item.disabled .site__item-name svg {
	fill:#999999;
}

.site__item.disabled .site__item-domain svg {
	fill:#999999;
}

.site__item.disabled .site__item-ssl svg {
	fill:#999999;
}

.site__item.disabled .site__item-name a svg {
	fill:#000000;
}

.site__item-status {
	font-weight:700;
	color:#008100;
	font-size:14px;
}

.site-edit__id {
	color:#999999;
	font-size:13px;
}

.site-edit__head {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	gap:20px;
	margin:10px 0 0 0;
}

.site-edit__name {
	font-size:27px;
	font-weight:600;
	line-height:1;
}

.site-edit__active label {
	margin:0;
	cursor:pointer;
}

.site-edit__param-label {
	display:flex;
	align-items:center;
	margin:0;
	line-height:1;
	cursor:pointer;
}

.site-edit__param-ico {
	margin:0 8px 0 0;
}

.site-edit__params {
	margin:10px 0 0 0;
	display:flex;
	align-items:center;
	flex-wrap:wrap;
	gap:30px;
}

.site-edit__btns {
	margin:30px 0 0 0;
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	gap:30px;
}

.site-edit__save {
	
}

.site-edit__delete button {
	min-width:unset;
}
